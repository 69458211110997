<template>
  <b-row>
    <b-col cols="12">
      <h2>Listar documentos</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">

        <template slot="filters"  >
          <b-col cols="3" class="my-3">
            <b-button
            variant="primary"
            class="btn-ghost"
            v-b-modal.modal-add-document-admin
            >
            Añadir nuevo documento 
            </b-button>
          </b-col>
        </template>
        
        <template slot-scope="{item}" slot="actions"  >
          <b-row class="actions-icons">
            <span title="ver" @click="show(  item.uuid  )" :id="'_show_button_'+item.uuid">  <i class="iconly-Light-Show"></i>  </span>
            <span title="editar" @click="edit(  item.uuid  )" :id="'_show_editar'+item.uuid"> <i class="iconly-Light-Edit mr-10"></i> </span>
            <span title="eliminar"@click="showDeleteConfirmation(item.uuid)" :id="'_show_eliminar'+item.uuid"> <i class="iconly-Light-Delete mr-10"></i> </span>
          </b-row>

          <b-tooltip :target="'_show_button_'+item.uuid" triggers="hover">
              Ver
            </b-tooltip>

            <b-tooltip :target="'_show_editar'+item.uuid" triggers="hover">
            Editar
            </b-tooltip>

            <b-tooltip :target="'_show_eliminar'+item.uuid" triggers="hover">
              Eliminar
            </b-tooltip>
        </template>

        <template  slot="pagination"  >
          <b-pagination
            align="end"
           
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>

      </datatable>
    </div>
 
    <!-- create document -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo producto</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
 
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input v-model="documentCreate.title" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Contenido">
              <b-form-input v-model="documentCreate.content" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12">
            <b-form-group label="Comentario">
              <b-form-input v-model="documentCreate.comment" type="text" ></b-form-input>
            </b-form-group>
          </b-col> -->

          <b-col cols="12">
            <b-form-group label="Documento">
              <b-form-file @change="uploadFile" accept=".jpg, .png, .gif, .pdf" ref="file-input"></b-form-file>

              <!-- <input type="file" @change="uploadFile" ref="file">
              <button @click="submitFile">Subir imagen</button> -->
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button 
              variant="primary"
              block
              @click="submitFile"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
          
        </b-row>
      </form>
    </b-modal>

     <!-- Modal de confirmación de eliminación -->
     <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este documento?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- edit document -->
    <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo producto</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
 
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input v-model="documentCreate.title" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Contenido">
              <b-form-input v-model="documentCreate.content" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button 
              variant="primary"
              block
              @click="submitFileEdit"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
          
        </b-row>
      </form>
    </b-modal>
    
    <!-- View document detail -->
    <b-modal
      id="modal-detail-document-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-detail-document-admin"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Ver detalle del documento</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>

          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input v-model="documentCreate.title" type="text" :disabled="true" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Contenido">
              <b-form-input v-model="documentCreate.title" type="text" :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-detail-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

  </b-row>
</template>

<script>
import {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormFile,
    BFormSelectOption,
    BFormDatepicker,
    BCalendar,
    BPagination  } from "bootstrap-vue";

    import datatable  from "@/layouts/components/datatable/Datatable.vue"
    import sweet from '@/logic/functions/sweetAlert';
    import documents from '@/logic/functions/documents';

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
 
  },
  data() {
    return {
      fields: [
        { key: "title", label: "Título" },
        { key: "content", label: "Contenido" },
        { key: "file.ext", label: "Documento tipo" },
        { key: "created_by", label: "Creado por" ,
        formatter: (value, key, item) => {
          let name = item.user.name;
          let name_two = item.user.name2 != null ? item.user.name2 : '';
          let last_name = item.user.last_name != null ? item.user.last_name : '';
          let last_name_two = item.user.last_name2 != null ? item.user.last_name2 : '';

          return name+' '+last_name+' '+name_two+' '+last_name_two;
        }
      },
        { key: "actions", label: "Acciones" },
      ],
      Actions:[ ],
      items: [
      ],
      loadNewUsers: [ 
      ],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      flags:{
        formUpload : false,
        fileUpload : false
      },
      createSales : {},
      documentCreate : {},
      paginationData : {},
        totalRows : 0,
        totalRowsPerpage : 5,
        currentPage : 0,
    };
  },
  methods: {
    show(uuid){

      this.showAdmin = this.items.find(item => {
        return item.uuid == uuid
      });

      window.open(this.showAdmin.file.path, '_blank');

      this.$bvModal.show('modal-show-admin');
    },
    edit(uuid){
      this.documentCreate = {};
      this.documentCreate = this.items.find(item => {
        return item.uuid == uuid
      });

      this.$bvModal.show('modal-edit-document-admin')
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deletes(uuid){
      let items = this.items;
      documents.delete(uuid).then((response) => {
        const index = items.findIndex(item => item.uuid === uuid)
            if (~index){
              items.splice(index, 1)
            } 
        sweet.ToastMsg('','success','Documento eliminado');
      }).catch((error) => {
    
      });
    },
    uploadFile(event){
      this.documentCreate.file = event.target.files[0];
    },
    modalClose(){
      if(this.flags.fileUpload == true && this.flags.formUpload == false){
        documents.delete( {
          params:{
            "document_uuid" : ""+this.createSales.img+""
          }
        } ).then((response) => {
        }).catch((error) => {
          this.flags.fileUpload = false;
          this.createSales.img = "";
        });
      }
    },
    async documents(page = 0){
      
      await documents.list({
        params:{
          'limit':5,
          'page': 0
        }
      }).then((response) => {
         this.items = response.data.data
      }).catch((error) => {
          
      });
    },
    async submitFile(){
      
      const params =  '?title='+this.documentCreate.title+'&content='+this.documentCreate.content +'&comment='+this.documentCreate.comment;
      const formData = new FormData();
      formData.append('file', this.documentCreate.file);

        await documents.create( formData,params ).then((response) => {
          this.flags.fileUpload = true;
          this.items.push(response.data);
          this.documentCreate.file = null;
          this.documentCreate = {};
          this.$bvModal.hide('modal-add-document-admin');
          sweet.ToastMsg('','success','Documento creado');
        }).catch((error) => {
          this.flags.fileUpload = false;
          sweet.ToastMsg('','error','El archivo no se pudo subir');
        });
    },
    async submitFileEdit(){

      await documents.update( this.documentCreate.uuid ,{
        "content": this.documentCreate.content,
        "title": this.documentCreate.title
      }).then((response) => {
        this.documents();
        sweet.ToastMsg('','success','Documento editado');
      }).catch((error) => {
        this.flags.fileUpload = false;
        sweet.ToastMsg('','error','Documento no editado');
      });
        
    },
    async pageChanged(page) {
      
      page = page == 1 ? 0 : page - 1 ;

      this.documents(page);
    },
    
  },
  beforeMount() {
    
  },
  mounted(){
    this.documents();
  },
};
</script>
